<template>
  <v-list-item
    exact
    dense
    v-if="node.create"
    :to="route"
    :target="target"
  >
    <v-list-item-icon v-if="!isChild">
      <v-icon>{{node.icon}}</v-icon>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title>{{node.label}}</v-list-item-title>
      <v-list-item-subtitle></v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import node from './node.js'
export default {
  mixins: [node],
  computed: {
    target() {
      if(this.node.blank === true) return '_blank'
      return '_self'
    },
    route() {
      if(typeof this.node.route != 'object') return null
      return this.node.route
    },
    active() {
      if(!this.route) return false
      if(typeof this.node.active === 'function') {
        return this.node.active(this.$route)
      }
      if(this.route.name != this.$route.name) return false
      if(window.eagleLodash.isEqual(this.route.params, this.$route.params)) return false
      return true
    },
  },
  watch: {
    active: {
      immediate: true,
      handler() {
        this.$emit('activeChange', this.active)
      }
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
